import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: { title: `${i18n.tc('Advance', 2)} POS` },
    path: '/pos/advances',
    name: 'pos-advances',
    component: () => import(/* webpackChunkName: "pos-advances-index" */ '@/views/pos/advances/Index.vue')
  }, {
    meta: { title: `${i18n.tc('CreditNote', 2)} POS` },
    path: '/pos/credit-notes',
    name: 'pos-credit-notes',
    component: () => import(/* webpackChunkName: "pos-credit-notes-index" */ '@/views/pos/credit-notes/Index.vue')
  }, {
    meta: { title: i18n.tc('Expense', 2) },
    path: '/pos/expenses',
    name: 'pos-expenses',
    component: () => import(/* webpackChunkName: "pos-expenses-index" */ '@/views/pos/expenses/Index.vue')
  }, {
    meta: { title: `${i18n.tc('Invoice', 2)} POS` },
    path: '/pos/invoices',
    name: 'pos-invoices',
    component: () => import(/* webpackChunkName: "pos-invoices-index" */ '@/views/pos/invoices/Index.vue')
  }, {
    meta: { title: `${i18n.tc('PosOrder', 2)} POS` },
    path: '/pos/orders',
    name: 'pos-orders',
    component: () => import(/* webpackChunkName: "pos-orders-index" */ '@/views/pos/orders/Index.vue')
  }, {
    meta: { title: `${i18n.tc('PaymentMean', 2)} POS` },
    path: '/pos/payment-types',
    name: 'pos-payment-types',
    component: () => import(/* webpackChunkName: "pos-payment-types-index" */ '@/views/pos/payment-types/Index.vue')
  }, {
    meta: { title: `${i18n.tc('RegisterClosure', 2)} POS` },
    path: '/pos/register-closures',
    name: 'register-closures',
    component: () => import(/* webpackChunkName: "register-closures-index" */ '@/views/pos/register-closures/Index.vue')
  }, {
    meta: { title: i18n.tc('Register') },
    path: '/pos/web-register',
    name: 'web-register',
    component: () => import(/* webpackChunkName: "web-register" */ '@/views/pos/web-register/Index.vue')
  }
]
