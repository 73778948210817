import messages from '@/locales/es-CO.js'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'es-CO', // set locale
  fallbackLocale: 'es-CO',
  messages: {
    'es-CO': messages
  } // set locale messages
})

const loadedLanguages = ['es-CO'] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  return lang
}

export async function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return setI18nLanguage(lang)
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang)
  }

  // If the language hasn't been loaded yet
  const msgs = await import(/* webpackChunkName: "lang-[request]" *//* @vite-ignore */ '../../locales/' + lang + '.js')

  i18n.setLocaleMessage(lang, msgs.default)
  loadedLanguages.push(lang)

  return setI18nLanguage(lang)
}
