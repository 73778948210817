import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('AccountingDocumentTemplate', 2)
    },
    path: '/accounting-templates',
    name: 'accounting-templates-index',
    component: () => import(/* webpackChunkName: "accounting-templates-index" */ '@/views/accounting-templates/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateAccountingDocumentTemplate')
    },
    path: '/accounting-templates/create',
    name: 'accounting-templates-create',
    component: () => import(/* webpackChunkName: "accounting-templates-create" */ '@/views/accounting-templates/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicateAccountingDocumentTemplate')
    },
    path: '/accounting-templates/duplicate/:id',
    name: 'accounting-templates-duplicate',
    component: () => import(/* webpackChunkName: "accounting-templates-duplicate" */ '@/views/accounting-templates/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditAccountingDocumentTemplate')
    },
    path: '/accounting-templates/edit/:id',
    name: 'accounting-templates-edit',
    component: () => import(/* webpackChunkName: "accounting-templates-edit" */ '@/views/accounting-templates/Edit.vue')
  }
]
