export default [
  {
    meta: {
      title: 'Tableros'
    },
    path: '/analytics/boards',
    name: 'analytics-boards',
    component: () => import(/* webpackChunkName: "analytics-boards" */ '@/views/analytics/boards/Index.vue')
  },
  {
    meta: {
      title: 'CRM Reportes'
    },
    path: '/analytics/crm/boards',
    name: 'analytics-crm-boards',
    component: () => import(/* webpackChunkName: "analytics-crm-boards" */ '@/views/analytics/crm/Index.vue')
  },
  {
    meta: {
      title: 'Presupuestos'
    },
    path: '/analytics/goals',
    name: 'analytics-goals',
    component: () => import(/* webpackChunkName: "analytics-goals" */ '@/views/analytics/goals/Index.vue')
  },
  {
    meta: {
      title: 'Impuestos'
    },
    path: '/analytics/taxes',
    name: 'analytics-taxes',
    component: () => import(/* webpackChunkName: "analytics-taxes" */ '@/views/analytics/taxes/Index.vue')
  },

  {
    meta: {
      title: 'Reportes'
    },
    path: '/analytics/reports',
    name: 'analytics-reports',
    component: () => import(/* webpackChunkName: "analytics-reports" */ '@/views/analytics/reports/Index.vue')
  },
  // {
  //   path: '/analytics/reports/backups',
  //   name: 'backups-reports',
  //   component: () => import(/* webpackChunkName: "analytics-backups" */ '@/views/analytics/reports/backups/Index.vue')
  // },
  {
    path: '/analytics/reports/document',
    name: 'document-reports',
    component: () => import(/* webpackChunkName: "analytics-document-reports" */ '@/views/analytics/reports/document/Index.vue')
  },
  {
    path: '/analytics/reports/exogenous-by-account',
    name: 'exogenous-by-account',
    component: () => import(/* webpackChunkName: "analytics-exogenous-by-account" */ '@/views/analytics/reports/exogenous/ByAccount.vue')
  },
  {
    path: '/analytics/reports/exogenous-by-format',
    name: 'exogenous-by-format',
    component: () => import(/* webpackChunkName: "analytics-exogenous-by-format" */ '@/views/analytics/reports/exogenous/ByFormat.vue')
  },
  {
    path: '/analytics/reports/treasury/payments',
    name: 'treasury-payments',
    component: () => import(/* webpackChunkName: "analytics-treasury-payments" */ '@/views/analytics/reports/treasury/Index.vue')
  },
  {
    path: '/analytics/reports/book-auxiliary',
    name: 'book-auxiliary',
    component: () => import(/* webpackChunkName: "analytics-book-auxiliary" */ '@/views/analytics/reports/book-auxiliary/Index.vue')
  },
  {
    path: '/analytics/reports/warnings',
    name: 'warnings',
    component: () => import(/* webpackChunkName: "analytics-warnings" */ '@/views/analytics/reports/warnings/Index.vue')
  },
  {
    path: '/analytics/reports/purchases/biz-documents',
    name: 'purchases-report',
    component: () => import(/* webpackChunkName: "analytics-purchases" */ '@/views/analytics/reports/purchases/Index.vue')
  },
  {
    path: '/analytics/reports/sales/export',
    name: 'sales-export',
    component: () => import(/* webpackChunkName: "analytics-sales-export" */ '@/views/analytics/reports/sales/Export.vue')
  },
  {
    path: '/analytics/reports/sales/export-summary',
    name: 'export-summary',
    component: () => import(/* webpackChunkName: "analytics-export-summary" */ '@/views/analytics/reports/sales/ExportSummary.vue')
  },
  {
    path: '/analytics/reports/sales/report-daily',
    name: 'report-daily',
    component: () => import(/* webpackChunkName: "analytics-report-daily" */ '@/views/analytics/reports/sales/ReportDaily.vue')
  },
  {
    path: '/analytics/reports/sales/commissions',
    name: 'commissions',
    component: () => import(/* webpackChunkName: "analytics-commissions" */ '@/views/analytics/reports/sales/Commissions.vue')
  },
  {
    path: '/analytics/reports/sales/virtual-zone',
    name: 'virtual-zone',
    component: () => import(/* webpackChunkName: "analytics-virtual-zone" */ '@/views/analytics/reports/sales/VirtualZone.vue')
  },
  {
    path: '/analytics/reports/export-invoices',
    name: 'export-invoices',
    component: () => import(/* webpackChunkName: "analytics-export-invoices" */ '@/views/analytics/reports/export-invoices/Index.vue')
  },
  {
    path: '/analytics/reports/balance',
    name: 'balance',
    component: () => import(/* webpackChunkName: "analytics-balance" */ '@/views/analytics/reports/balance/Index.vue')
  },
  {
    path: '/analytics/reports/state-change-heritage',
    name: 'state-change-heritage',
    component: () => import(/* webpackChunkName: "analytics-state-change-heritage" */ '@/views/analytics/reports/state-change-heritage/Index.vue')
  },
  {
    path: '/analytics/reports/status-result',
    name: 'status-result',
    component: () => import(/* webpackChunkName: "analytics-status-result" */ '@/views/analytics/reports/status-result/Index.vue')
  },
  {
    path: '/analytics/reports/production',
    name: 'production',
    component: () => import(/* webpackChunkName: "analytics-production" */ '@/views/analytics/reports/production/Index.vue')
  },
  {
    path: '/analytics/reports/inventories',
    name: 'inventories',
    component: () => import(/* webpackChunkName: "analytics-inventories" */ '@/views/analytics/reports/inventories/Index.vue')
  },
  {
    path: '/analytics/reports/inventories/alarms',
    name: 'inventory-alarms',
    component: () => import(/* webpackChunkName: "analytics-inventory-alarms" */ '@/views/analytics/reports/inventories/Alarms.vue')
  },
  {
    path: '/analytics/reports/inventories/batches',
    name: 'inventories-batches',
    component: () => import(/* webpackChunkName: "analytics-inventory-batches" */ '@/views/analytics/reports/inventories/Batches.vue')
  },
  {
    path: '/analytics/reports/inventories/serials',
    name: 'inventories-serials',
    component: () => import(/* webpackChunkName: "analytics-inventory-serials" */ '@/views/analytics/reports/inventories/Serials.vue')
  },
  {
    path: '/analytics/reports/taxes',
    name: 'taxes',
    component: () => import(/* webpackChunkName: "analytics-taxes" */ '@/views/analytics/reports/taxes/Index.vue')
  }
]
