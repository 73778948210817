import router from '@/router'
export default {
  namespaced: true,
  state: {
    user: {
      userFirstName: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userFirstName : '',
      userLastName: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userLastName : '',
      userEmail: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userEmail : '',
      userAvatar: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userAvatar : '',
      userId: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userId : '',
      userPhone: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userPhone : '',
      userPhoneCountryCode: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).userPhoneCountryCode : ''
    }
  },
  mutations: {
    SET_USER (state, payload) {
      state.user = payload
      localStorage.setItem('userData', JSON.stringify(payload))
    },
    LOGOUT (state) {
      localStorage.removeItem('dataCompany')
      localStorage.removeItem('subscriptionId')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('subscriptionFeatures')
      localStorage.removeItem('userData')
      localStorage.removeItem('isAuthenticated')
      state.user.userFirstName = ''
      state.user.userLastName = ''
      state.user.userEmail = ''
      state.user.userAvatar = ''
      state.user.userId = ''
      router.push({ name: 'login' })
    }
  },
  actions: {
  },
  getters: {
    getUser (state) {
      return state.user
    }
  }
}
