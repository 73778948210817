import { i18n } from '@/plugins/i18n'

export default [
  /* --------------------------- Facturas de ventas --------------------------- */
  {
    meta: {
      title: i18n.tc('SaleInvoice')
    },
    path: '/sales/invoices/:id/view',
    name: 'sales-invoices-view',
    component: () => import(/* webpackChunkName: "sales-invoices-view" */ '@/views/sales/invoices/View.vue')
  },
  {
    meta: {
      title: i18n.tc('SaleInvoice', 2)
    },
    path: '/sales/invoices',
    name: 'sales-invoices-index',
    component: () => import(/* webpackChunkName: "sales-invoices-index" */ '@/views/sales/invoices/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('SaleInvoice', 2)
    },
    path: '/sales/invoices-d',
    name: 'sales-invoices-index-d',
    component: () => import(/* webpackChunkName: "sales-invoices-index-dinamic-column" */ '@/views/sales/invoices/IndexDinamicColumn.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateSaleInvoice')
    },
    path: '/sales/invoices/create',
    name: 'sales-invoices-create',
    component: () => import(/* webpackChunkName: "sales-invoices-create" */ '@/views/sales/invoices/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditSaleInvoice')
    },
    path: '/sales/invoices/:id/edit',
    name: 'sales-invoices-edit',
    component: () => import(/* webpackChunkName: "sales-invoices-edit" */ '@/views/sales/invoices/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicateSaleInvoice')
    },
    path: '/sales/invoices/:id/duplicate',
    name: 'sales-invoices-duplicate',
    component: () => import(/* webpackChunkName: "sales-invoices-edit" */ '@/views/sales/invoices/Create.vue')
  },

  /* --------------- Notas créditos en ventas - (SaleCreditNote) -------------- */
  {
    meta: {
      title: i18n.tc('SaleCreditNote')
    },
    path: '/sales/invoice-credit-notes/:id/view',
    name: 'sales-invoice-credit-notes-view',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-view" */ '@/views/sales/invoice-credit-notes/View.vue')
  },
  {
    meta: {
      title: i18n.tc('SaleCreditNote', 2)
    },
    path: '/sales/invoice-credit-notes',
    name: 'sales-invoice-credit-notes-index',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-index" */ '@/views/sales/invoice-credit-notes/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateCreditNote')
    },
    path: '/sales/invoice-credit-notes/create',
    name: 'sales-invoice-credit-notes-create',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-create" */ '@/views/sales/invoice-credit-notes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateCreditNote')
    },
    path: '/sales/invoice-credit-notes/:idParent/create',
    name: 'sales-invoice-credit-notes-create-by-id',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-create-by-id" */ '@/views/sales/invoice-credit-notes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditCreditNote')
    },
    path: '/sales/invoice-credit-notes/:id/edit',
    name: 'sales-invoice-credit-notes-edit',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-edit" */ '@/views/sales/invoice-credit-notes/Edit.vue')
  },

  /* --------------- Notas débito en ventas - (SaleDebitNote) -------------- */
  {
    meta: {
      title: i18n.tc('SaleDebitNote')
    },
    path: '/sales/invoice-debit-notes/:id/view',
    name: 'sales-invoice-debit-notes-view',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-view" */ '@/views/sales/invoice-debit-notes/View.vue')
  },
  {
    meta: {
      title: i18n.tc('SaleDebitNote', 2)
    },
    path: '/sales/invoice-debit-notes',
    name: 'sales-invoice-debit-notes-index',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-index" */ '@/views/sales/invoice-debit-notes/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateSaleDebitNote')
    },
    path: '/sales/invoice-debit-notes/create',
    name: 'sales-invoice-debit-notes-create',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-create" */ '@/views/sales/invoice-debit-notes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateSaleDebitNote')
    },
    path: '/sales/invoice-debit-notes/:idParent/create',
    name: 'sales-invoice-debit-notes-create-by-id',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-create-by-id" */ '@/views/sales/invoice-debit-notes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditSaleDebitNote')
    },
    path: '/sales/invoice-debit-notes/:id/edit',
    name: 'sales-invoice-debit-notes-edit',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-edit" */ '@/views/sales/invoice-debit-notes/Edit.vue')
  },

  /* --------------- Remisiones de venta - (SaleRemissions) -------------- */
  {
    meta: {
      title: i18n.tc('SaleRemission')
    },
    path: '/sales/remissions/:id/view',
    name: 'sales-remissions-view',
    component: () => import(/* webpackChunkName: "sales-remissions-view" */ '@/views/sales/remissions/View.vue')
  },
  {
    meta: {
      title: i18n.tc('SaleRemission', 2)
    },
    path: '/sales/remissions',
    name: 'sales-remissions-index',
    component: () => import(/* webpackChunkName: "sales-remissions-index" */ '@/views/sales/remissions/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateSaleRemission')
    },
    path: '/sales/remissions/create',
    name: 'sales-remissions-create',
    component: () => import(/* webpackChunkName: "sales-remissions-create" */ '@/views/sales/remissions/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicateSaleRemission')
    },
    path: '/sales/remissions/:id/duplicate',
    name: 'sales-remissions-duplicate',
    component: () => import(/* webpackChunkName: "sales-remissions-duplicate" */ '@/views/sales/remissions/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditSaleRemission')
    },
    path: '/sales/remissions/:id/edit',
    name: 'sales-remissions-edit',
    component: () => import(/* webpackChunkName: "sales-remissions-edit" */ '@/views/sales/remissions/Edit.vue')
  },

  /* --------------- Órdenes de venta - (SaleOrders) -------------- */
  {
    meta: {
      title: i18n.tc('SaleOrder')
    },
    path: '/sales/orders/:id/view',
    name: 'sales-orders-view',
    component: () => import(/* webpackChunkName: "sales-orders-view" */ '@/views/sales/orders/View.vue')
  },
  {
    meta: {
      title: i18n.tc('SaleOrder', 1)
    },
    path: '/sales/orders',
    name: 'sales-orders-index',
    component: () => import(/* webpackChunkName: "sales-orders-index" */ '@/views/sales/orders/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateSaleOrder')
    },
    path: '/sales/orders/create',
    name: 'sales-orders-create',
    component: () => import(/* webpackChunkName: "sales-orders-create" */ '@/views/sales/orders/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicateSaleOrder', 1)
    },
    path: '/sales/orders/:id/duplicate',
    name: 'sales-orders-duplicate',
    component: () => import(/* webpackChunkName: "sales-orders-duplicate" */ '@/views/sales/orders/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditSaleOrder')
    },
    path: '/sales/orders/:id/edit',
    name: 'sales-orders-edit',
    component: () => import(/* webpackChunkName: "sales-orders-edit" */ '@/views/sales/orders/Edit.vue')
  },

  /* --------------- Cotizaciones - (Quotes) -------------- */
  {
    meta: {
      title: i18n.tc('Quote')
    },
    path: '/sales/quotes/:id/view',
    name: 'sales-quotes-view',
    component: () => import(/* webpackChunkName: "sales-quotes-view" */ '@/views/sales/quotes/View.vue')
  },
  {
    meta: {
      title: i18n.tc('Quote', 2)
    },
    path: '/sales/quotes',
    name: 'sales-quotes-index',
    component: () => import(/* webpackChunkName: "sales-quote-index" */ '@/views/sales/quotes/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateQuote', 1)
    },
    path: '/sales/quotes/create',
    name: 'sales-quotes-create',
    component: () => import(/* webpackChunkName: "sales-quote-create" */ '@/views/sales/quotes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicateQuote', 1)
    },
    path: '/sales/quotes/:id/duplicate',
    name: 'sales-quotes-diplicate',
    component: () => import(/* webpackChunkName: "sales-quote-duplicate" */ '@/views/sales/quotes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditQuote', 1)
    },
    path: '/sales/quotes/:id/edit',
    name: 'sales-quotes-edit',
    component: () => import(/* webpackChunkName: "sales-quote-edit" */ '@/views/sales/quotes/Edit.vue')
  },

  /* --------------- Facturas de recurrencia - (InvoiceRecurrences) -------------- */
  {
    meta: {
      title: i18n.tc('RecurrenceInvoice', 2)
    },
    path: '/sales/order-recurrences',
    name: 'sales-order-recurrences-index',
    component: () => import(/* webpackChunkName: "sales-order-recurrences-index" */ '@/views/sales/order-recurrences/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateRecurrenceInvoice')
    },
    path: '/sales/order-recurrences/create',
    name: 'sales-order-recurrences-create',
    component: () => import(/* webpackChunkName: "sales-order-recurrences-create" */ '@/views/sales/order-recurrences/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditRecurrenceInvoice')
    },
    path: '/sales/order-recurrences/:id/edit',
    name: 'sales-order-recurrences-edit',
    component: () => import(/* webpackChunkName: "sales-order-recurrences-edit" */ '@/views/sales/order-recurrences/Edit.vue')
  }
]
