import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: 'Vista detallada de producto'
    },
    path: '/items/:id/view',
    name: 'items-view',
    component: () => import(/* webpackChunkName: "items-view" */ '@/views/items/ViewItems.vue')
  },
  {
    meta: {
      title: i18n.tc('Product', 2)
    },
    path: '/items',
    name: 'items-index',
    component: () => import(/* webpackChunkName: "items-index" */ '@/views/items/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Batch', 2)
    },
    path: '/items/batches',
    name: 'items-batches',
    component: () => import(/* webpackChunkName: "items-batches-index" */ '@/views/items/batches/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Serial', 2)
    },
    path: '/items/serials',
    name: 'items-serials',
    component: () => import(/* webpackChunkName: "items-serials-index" */ '@/views/items/serials/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('ImportProducts')
    },
    path: '/items/upload/type=:type',
    name: 'items-upload',
    component: () => import(/* webpackChunkName: "items-upload" */ '@/views/items/Upload.vue')
  },
  {
    meta: {
      title: i18n.tc('Price', 2)
    },
    path: '/items/price',
    name: 'items-price',
    component: () => import(/* webpackChunkName: "items-price-index" */ '@/views/items/price/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('ImportProductPrices')
    },
    path: '/items/price/upload',
    name: 'items-price-upload',
    component: () => import(/* webpackChunkName: "items-price-upload" */ '@/views/items/price/Upload.vue')
  }
]
