import { i18n } from '@/plugins/i18n'

export default [

  /* ----------------- Asientos contables - (AccountingEntry) ----------------- */
  {
    meta: {
      title: i18n.tc('AccountingEntry', 2),
      default: true
    },
    path: '/accounting/journals',
    name: 'accounting-journals-index',
    component: () => import(/* webpackChunkName: "accounting-journals-index-[hash]" */ '@/views/accounting/journals/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateVoucher')
    },
    path: '/accounting/journals/create',
    name: 'accounting-journals-create',
    component: () => import(/* webpackChunkName: "accounting-journals-create" */ '@/views/accounting/journals/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditAccountingEntry')
    },
    path: '/accounting/journals/edit/:id',
    name: 'accounting-journals-edit',
    component: () => import(/* webpackChunkName: "accounting-journals-edit" */ '@/views/accounting/journals/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('EditAccountingEntry')
    },
    path: '/accounting/journals/edit-bank-conciliation/:id',
    name: 'accounting-journals-edit-bank-conciliation',
    component: () => import(/* webpackChunkName: "accounting-journals-edit-bank-conciliation" */ '@/views/accounting/journals/EditBankConciliation.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicateAccountingEntry')
    },
    path: '/accounting/journals/duplicate/:id',
    name: 'accounting-journals-duplicate',
    component: () => import(/* webpackChunkName: "accounting-journals-duplicate" */ '@/views/accounting/journals/Duplicate.vue')
  },

  /* ----------------- Cuentas Contables - (AccountingAccount) ---------------- */
  {
    meta: {
      title: i18n.tc('ImportAccountingEntry')
    },
    path: '/accounting/journals/upload',
    name: 'accounting-journals-upload',
    component: () => import(/* webpackChunkName: "accounting-journals-upload" */ '@/views/accounting/journals/Upload.vue')
  },
  {
    meta: {
      title: i18n.tc('AccountingAccount', 2)
    },
    path: '/accounting/chart-accounts',
    name: 'accounting-chart-accounts-index',
    component: () => import(/* webpackChunkName: "accounting-chart-accounts-index" */ '@/views/accounting/chart-accounts/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('BankConciliation')
    },
    path: '/accounting/bank-conciliation',
    name: 'accounting-bank-conciliation',
    component: () => import(/* webpackChunkName: "accounting-bank-conciliation-index" */ '@/views/accounting/bank-conciliation/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('ImportAccountingEntryBalance')
    },
    path: '/accounting/journals/upload-balance',
    name: 'accounting-journals-upload-balance',
    component: () => import(/* webpackChunkName: "accounting-journals-upload-balance" */ '@/views/accounting/journals/UploadBalance.vue')
  },
  {
    meta: {
      title: i18n.tc('ImportChartOfAccounts')
    },
    path: '/accounting/chart-accounts/upload',
    name: 'accounting-chart-accounts-upload',
    component: () => import(/* webpackChunkName: "accounting-chart-accounts-upload" */ '@/views/accounting/chart-accounts/Upload.vue')
  },
  {
    meta: {
      title: i18n.tc('WithholdingCertificate', 2)
    },
    path: '/accounting/certificates',
    name: 'accounting-certificates',
    component: () => import(/* webpackChunkName: "accounting-certificates-index" */ '@/views/accounting/certificates/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('AccountingClosure')
    },
    path: '/accounting/closure-accounting',
    name: 'accounting-closure-accounting',
    component: () => import(/* webpackChunkName: "accounting-closure-accounting-index" */ '@/views/accounting/closure-accounting/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Reclassification')
    },
    path: '/accounting/reclassifications',
    name: 'accounting-reclassifications',
    component: () => import(/* webpackChunkName: "accounting-reclassifications-index" */ '@/views/accounting/reclassifications/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('RenumberAccountingDocument')
    },
    path: '/accounting/journal-renumbers',
    name: 'accounting-journal-renumber',
    component: () => import(/* webpackChunkName: "accounting-journal-renumber-index" */ '@/views/accounting/journal-renumbers/Index.vue')
  }
]
