import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: `${i18n.tc('MyPlan')} / ${i18n.tc('MyCompany', 2)}`
    },
    path: '/account/my-companies',
    name: 'my-companies',
    component: () => import(/* webpackChunkName: "my-companies-index" */ '@/views/account/my-companies/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Invoicing')
    },
    path: '/account/vouchers',
    name: 'account-vouchers-index',
    component: () => import(/* webpackChunkName: "my-companies-vouchers" */ '@/views/account/vouchers/Index.vue')
  }
]
