import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize as veeValidateLocalize } from 'vee-validate'
import * as veeValidateRules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import es from 'vee-validate/dist/locale/es.json'
import { DateTime } from 'luxon'
import { toDate } from './filters'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Object.keys(veeValidateRules).forEach(rule => extend(rule, veeValidateRules[rule]))
veeValidateLocalize({ en, es })
veeValidateLocalize('es')
/** CUSTOM RULES */

extend('characterSpecial', {
  // eslint-disable-next-line no-useless-escape
  validate: value => value.match(/[\.\*\+\=\?\^\$\{\}\(\)\|\[\]\\\/]/g) !== null,
  message: () => 'Ingresa un caracter especial (Ejemplo: , . _ & ?)'
})

extend('upperCase', {
  validate: value => value.match(/[A-Z]/g) !== null,
  message: () => 'Ingresa al menos 1 letra mayúscula'
})

extend('lowerCase', {
  validate: value => value.match(/[a-z]/g) !== null,
  message: () => 'Ingresa al menos letra minúscula'
})

extend('onDigit', {
  validate: value => value.match(/[0-9]/g) !== null,
  message: () => 'Ingresa al menos un dígito'
})

// #region Fechas

extend('date_gt', {
  validate: (date, { min_date: minDate }) => !minDate || DateTime.fromISO(date) > DateTime.fromISO(minDate),
  params: ['min_date'],
  message: (fieldName, { _min_date_: minDate }) => `El campo ${fieldName} debe ser mayor a ${toDate(minDate)}`
})

extend('date_gte', {
  validate: (date, { min_date: minDate }) => !minDate || DateTime.fromISO(date) >= DateTime.fromISO(minDate),
  params: ['min_date'],
  message: (fieldName, { _min_date_: minDate }) => `El campo ${fieldName} debe ser mayor o igual a ${toDate(minDate)}`
})

extend('date_lt', {
  validate: (date, { max_date: maxDate }) => !maxDate || DateTime.fromISO(date) < DateTime.fromISO(maxDate),
  params: ['max_date'],
  message: (fieldName, { _max_date_: maxDate }) => `El campo ${fieldName} debe ser menor a ${toDate(maxDate)}`
})

extend('date_lte', {
  validate: (date, { max_date: maxDate }) => !maxDate || DateTime.fromISO(date) <= DateTime.fromISO(maxDate),
  params: ['max_date'],
  message: (fieldName, { _max_date_: maxDate }) => `El campo ${fieldName} debe ser menor o igual a ${toDate(maxDate)}`
})

// #endregion

// #region Números

extend('number_gt', {
  validate: (number, { min_number: minNumber }) => {
    if (minNumber && typeof minNumber !== 'number') {
      minNumber = Number(minNumber)
    }

    return number > minNumber
  },
  params: ['min_number'],
  message: (fieldName, { min_number: minNumber }) => `El campo ${fieldName} debe ser mayor a ${minNumber}`
})

extend('number_gte', {
  validate: (number, { min_number: minNumber }) => {
    if (minNumber && typeof minNumber !== 'number') {
      minNumber = Number(minNumber)
    }

    return number >= minNumber
  },
  params: ['min_number'],
  message: (fieldName, { min_number: minNumber }) => `El campo ${fieldName} debe ser mayor o igual a ${minNumber}`
})

extend('number_lt', {
  validate: (number, { max_number: maxNumber }) => {
    if (maxNumber && typeof maxNumber !== 'number') {
      maxNumber = Number(maxNumber)
    }

    return number < maxNumber
  },
  params: ['max_number'],
  message: (fieldName, { max_number: maxNumber }) => `El campo ${fieldName} debe ser menor a ${maxNumber}`
})

extend('number_lte', {
  validate: (number, { max_number: maxNumber }) => {
    if (maxNumber && typeof maxNumber !== 'number') {
      maxNumber = Number(maxNumber)
    }

    return number <= maxNumber
  },
  params: ['max_number'],
  message: (fieldName, { max_number: maxNumber }) => `El campo ${fieldName} debe ser menor o igual a ${maxNumber}`
})

// #endregion
