import { i18n } from '@/plugins/i18n'
export default [
  {
    meta: {
      title: i18n.tc('Activity', 2),
      default: true
    },
    path: '/crm/activities',
    name: 'activities-index',
    component: () => import(/* webpackChunkName: "crm-activities-index" */ '@/views/crm/activities/Index.vue')
  }, {
    meta: {
      title: i18n.tc('Deal', 2),
      default: true
    },
    path: '/crm/deals',
    name: 'deals-index',
    component: () => import(/* webpackChunkName: "crm-deals-index" */ '@/views/crm/deals/Index.vue')
  }, {
    meta: {
      title: i18n.tc('DealDetails')
    },
    path: '/crm/deals/:id/view',
    name: 'deals-show',
    component: () => import(/* webpackChunkName: "crm-deals-show" */ '@/views/crm/deals/Show.vue')
  },
  /* --------------- Procesos - (Process) -------------- */
  {
    meta: {
      title: i18n.tc('Process', 2)
    },
    path: '/crm/settings/pipelines',
    name: 'setups-pipelines-index',
    component: () => import(/* webpackChunkName: "settings-pipelines-index" */ '@/views/crm/settings/pipelines/Index.vue')
  },

  /* --------------- Plantillas de correo - (EmailTemplates) -------------- */
  {
    meta: {
      title: i18n.tc('EmailTemplate', 2)
    },
    path: '/crm/settings/email-templates',
    name: 'settings-email-templates',
    component: () => import(/* webpackChunkName: "settings-email-templates-index" */ '@/views/crm/settings/email-templates/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateTemplate', 1)
    },
    path: '/crm/settings/email-templates/create',
    name: 'settings-email-templates-create',
    component: () => import(/* webpackChunkName: "settings-email-templates-create" */ '@/views/crm/settings/email-templates/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditTemplate', 1)
    },
    path: '/crm/settings/email-templates/edit/id=:id',
    name: 'settings-email-templates-edit',
    component: () => import(/* webpackChunkName: "settings-email-templates-edit" */ '@/views/crm/settings/email-templates/Edit.vue')
  },

  /* --------------- Resultado de la actividad (ActivityResults) -------------- */
  {
    meta: {
      title: i18n.tc('ActivityResult', 2)
    },
    path: '/crm/settings/activity-outcomes',
    name: 'settings-activity-outcomes-index',
    component: () => import(/* webpackChunkName: "settings-activity-outcomes-index" */ '@/views/crm/settings/activity-outcomes/Index.vue')
  },

  /* --------------- Fuentes (Sources) -------------- */
  {
    meta: {
      title: i18n.tc('Source', 2)
    },
    path: '/crm/settings/origins',
    name: 'settings-origins-index',
    component: () => import(/* webpackChunkName: "settings-origins-index" */ '@/views/crm/settings/origins/Index.vue')
  },

  /* --------------- Territorios (Territories) -------------- */
  {
    meta: {
      title: i18n.tc('Territory', 2)
    },
    path: '/crm/settings/territories',
    name: 'settings-territories-index',
    component: () => import(/* webpackChunkName: "settings-territories-index" */ '@/views/crm/settings/territories/Index.vue')
  },

  /* --------------- Tipos de avtividad (activityTypes) -------------- */
  {
    meta: {
      title: i18n.tc('ActivityType', 2)
    },
    path: '/crm/settings/activity-types',
    name: 'settings-activity-types',
    component: () => import(/* webpackChunkName: "setups-activity-types-index" */ '@/views/crm/settings/activity-types/Index.vue')
  },

  /* --------------- Etapas del ciclo de vida del Contacto (activityTypes) -------------- */
  {
    meta: {
      title: i18n.tc('ContactLifeCycleStage')
    },
    path: '/crm/settings/contact-stages',
    name: 'settings-contact-stages-index',
    component: () => import(/* webpackChunkName: "settings-contact-stages-index" */ '@/views/crm/settings/contact-stages/Index.vue')
  },

  /* --------------- Motivos de pérdida (ReasonLoss) -------------- */
  {
    meta: {
      title: i18n.tc('LossReason', 2)
    },
    path: '/crm/settings/reasons-loss',
    name: 'settings-reasons-loss-index',
    component: () => import(/* webpackChunkName: "settings-reasons-loss-index" */ '@/views/crm/settings/reasons-loss/Index.vue')
  },
  /* --------------- Competidores -------------- */
  {
    meta: {
      title: i18n.tc('Competitor', 2)
    },
    path: '/crm/settings/competitors',
    name: 'settings-competitors-index',
    component: () => import(/* webpackChunkName: "settings-competitors-index" */ '@/views/crm/settings/competitors/Index.vue')
  },
  /* --------------- Como te enteraste de nosotros ? -------------- */
  {
    meta: {
      title: i18n.tc('HeardAboutUs')
    },
    path: '/crm/settings/heard-about-us',
    name: 'settings-heard-about-us-index',
    component: () => import(/* webpackChunkName: "settings-heard-about-us-index" */ '@/views/crm/settings/heard-about-us/Index.vue')
  }

]
