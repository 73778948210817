import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: ''
    },
    path: '/hidden-views',
    name: 'hidden-views',
    component: () => import(/* webpackChunkName: "hidden-views-index" */ '@/views/hidden-views/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('DeskPanel')
    },
    path: '/hidden-views/desk-panel',
    name: 'hidden-desk-panel',
    component: () => import(/* webpackChunkName: "hidden-desk-panel-index" */ '@/views/hidden-views/desk-panel/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('PushNotificationCreate')
    },
    path: '/hidden-views/push-notifications/create',
    name: 'hidden-push-notification-create',
    component: () => import(/* webpackChunkName: "hidden-push-notification-create" */ '@/views/hidden-views/push-notifications/FormCreate.vue')
  }
]
