import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('AliaddoStore')
    },
    path: '/store',
    name: 'store-index',
    component: () => import(/* webpackChunkName: "store-index" */ '@/views/play-store/Index.vue')
  }
]
