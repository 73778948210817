// eslint-disable-next-line camelcase
// import keyBy from 'lodash/keyBy'
import { set, entries } from 'idb-keyval'
export default {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    SET_NOTIFICATIONS (state, notifications) {
      state.notifications = notifications
    }
  },
  actions: {
    setReadNotification ({ dispatch }, key) {
      return set(key, true).then(() => dispatch('getNotifications'))
    },
    setUnreadNotification ({ dispatch }, key) {
      return set(key, false).then(() => dispatch('getNotifications'))
    },
    getNotifications ({ commit }) {
      entries().then((entries) => {
        console.log('-->entries ', entries)
        commit('SET_NOTIFICATIONS', entries)
      })
    }
  },
  getters: {
    getNotificationByKey: (state) => (key) => {
      return state.notifications.filter(item => item.id === key)
    }
  }
}
