import { i18n } from '@/plugins/i18n'
export default [
  {
    meta: {
      title: i18n.tc('ProductionOrder', 2)
    },
    path: '/manufacture',
    name: 'manufacture-index',
    component: () => import(/* webpackChunkName: "manufacture-index" */ '@/views/manufacture/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateProductionOrder')
    },
    path: '/manufacture/create',
    name: 'manufacture-create',
    component: () => import(/* webpackChunkName: "manufacture-create" */ '@/views/manufacture/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditProductionOrder')
    },
    path: '/manufacture/:id',
    name: 'manufacture-edit',
    component: () => import(/* webpackChunkName: "manufacture-edit" */ '@/views/manufacture/Edit.vue')
  }
]
