<template>
  <b-icon
    :icon="icon ? icon : active ? 'play-circle' : 'pause-circle'"
    :type="type ? type : active ? 'is-success' : 'is-danger'"
    size="is-medium"
  />
</template>

<script>
export default {
  name: 'AppIconEnabled',
  props: {
    active: Boolean,
    icon: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  setup (props) {
    return {
    }
  }
}
</script>
