import { formatToCurrency } from '@/filters/index'

export default {
  inserted: function (el, binding, vnode) {
    let inputEl = el
    let originalType = null
    let unformatedValue = null

    if (!(el instanceof HTMLInputElement)) {
      inputEl = el.getElementsByTagName('input')[0]
    }

    if (!inputEl) return

    originalType = inputEl.type
    unformatedValue = inputEl.value

    inputEl.type = 'text'
    inputEl.value = formatToCurrency(unformatedValue || 0.00)

    inputEl.addEventListener('blur', function inputCurrencyMaskOnBlur (e) {
      inputEl.type = 'text'
      inputEl.value = formatToCurrency(unformatedValue || 0.00)
    })
    inputEl.addEventListener('focus', function inputCurrencyMaskOnFocus (e) {
      inputEl.type = originalType
      inputEl.value = parseFloat(unformatedValue) || null
    })
    inputEl.addEventListener('input', function inputCurrencyMaskOnInput (e) {
      unformatedValue = e.target.value
    })
    inputEl.addEventListener('change', function inputCurrencyMaskOnChange (e) {
      unformatedValue = e.target.value
    })
    inputEl.addEventListener('vnodeUpdate', function inputCurrencyMaskOnChange (e) {
      unformatedValue = e.detail.unformatedValue
    })
  },
  update: function (el, binding, vnode) {
    let inputEl = el

    if (!(el instanceof HTMLInputElement)) {
      inputEl = el.getElementsByTagName('input')[0]
    }

    if (!inputEl) return

    if (document.activeElement !== inputEl) {
      Promise.resolve().then(function () {
        let value = 0.00

        if (vnode.data.model) {
          value = vnode.data.model.value || 0.00
        } else if (vnode.componentOptions.propsData.value) {
          value = vnode.componentOptions.propsData.value || 0.00
        }

        inputEl.type = 'text'
        inputEl.value = formatToCurrency(value)
        inputEl.dispatchEvent(new CustomEvent('vnodeUpdate', {
          detail: { unformatedValue: value }
        }))
      })
    }
  }
}
