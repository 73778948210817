import { i18n } from '@/plugins/i18n'

export default [
  /* #region Nómina lite */
  {
    meta: {
      layout: 'layout-blank',
      requiresAuth: false,
      title: i18n.tc('Download', 2, [i18n.tc('Payroll').toLowerCase()])
    },
    path: '/public/payrolls-lite/download/:companyServiceApiKey/:documentPeriod/:documentMonth/:id',
    name: 'payrolls-download',
    component: () => import(/* webpackChunkName: "payrolls-download" */ '@/views/public/payrolls-lite/Download.vue')
  },
  {
    meta: {
      layout: 'layout-blank',
      requiresAuth: false,
      title: i18n.tc('Download', 2, [i18n.tc('Payroll').toLowerCase()])
    },
    path: '/public/payrolls-lite/download/:companyServiceApiKey/:documentPeriod/:documentMonth/:id/:documentType',
    name: 'payrolls-download-adjustment',
    component: () => import(/* webpackChunkName: "payrolls-download" */ '@/views/public/payrolls-lite/Download.vue')
  },
  /* #endregion Nómina lite */

  /* #region Calificación de los chats y tickets de soporte */
  {
    meta: {
      layout: 'layout-blank',
      requiresAuth: false,
      title: 'Califica tu experiencia con nuestro agente'
    },
    path: '/public/desk/chat-survey',
    name: 'customer-chat-survey',
    component: () => import(/* webpackChunkName: "customer-chat-survey" */ '@/views/public/desk/ChatSurvey.vue')
  },
  {
    meta: {
      layout: 'layout-blank',
      requiresAuth: false,
      title: 'Califica tu experiencia con nuestro agente'
    },
    path: '/public/desk/ticket-survey',
    name: 'customer-ticket-survey',
    component: () => import(/* webpackChunkName: "customer-ticket-survey" */ '@/views/public/desk/TicketSurvey.vue')
  },
  /* #endregion Calificación de los chats y tickets de soporte */

  /* #region Fidelización */
  {
    meta: {
      layout: 'layout-blank',
      requiresAuth: false,
      title: 'Bienvenida al programa de fidelización'
    },
    path: '/public/loyalty/registration/:companyKey/:loyaltyCode',
    name: 'customer-loyalty-registration',
    component: () => import(/* webpackChunkName: "customer-loyalty-registration" */ '@/views/public/loyalty/Registration.vue')
  },
  /* #endregion Fidelización */

  /* #region [TRACKING] Factura de venta */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('SaleInvoice', 1)
    },
    path: '/sales/invoices/:id/tracking',
    name: 'sales-invoices-tracking-index',
    component: () => import(/* webpackChunkName: "sales-invoices-tracking-index" */ '@/views/sales/invoices/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('SaleInvoice', 1)
    },
    path: '/invoices/tracking',
    name: 'old-invoices-tracking-index',
    component: () => import(/* webpackChunkName: "sales-invoices-tracking-index" */ '@/views/sales/invoices/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-print',
      requiresAuth: false,
      title: i18n.tc('SaleInvoice', 1)
    },
    path: '/sales/invoices/:id/print',
    name: 'sales-invoices-print',
    component: () => import(/* webpackChunkName: "sales-invoices-print" */ '@/views/sales/invoices/Print.vue')
  },
  /* #endregion [TRACKING] Factura de venta */

  /* #region [TRACKING] Nota crédito de venta */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('SeeCreditNote', 1)
    },
    path: '/sales/invoice-credit-notes/:id/tracking',
    name: 'sales-invoice-credit-notes-tracking-index',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-tracking-index" */ '@/views/sales/invoice-credit-notes/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-print',
      requiresAuth: false,
      title: i18n.tc('SaleCreditNote', 2)
    },
    path: '/sales/invoice-credit-notes/:id/print',
    name: 'sales-invoice-credit-notes-print',
    component: () => import(/* webpackChunkName: "sales-invoice-credit-notes-print" */ '@/views/sales/invoice-credit-notes/Print.vue')
  },
  /* #endregion [TRACKING] Nota crédito de venta */

  /* #region [TRACKING] Nota débito de venta */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('SeeDebitNote', 1)
    },
    path: '/sales/invoice-debit-notes/:id/tracking',
    name: 'sales-invoice-debit-notes-tracking-index',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-tracking-index" */ '@/views/sales/invoice-debit-notes/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-print',
      requiresAuth: false,
      title: i18n.tc('SaleDebitNote', 1)
    },
    path: '/sales/invoice-debit-notes/:id/print',
    name: 'sales-invoice-debit-notes-print',
    component: () => import(/* webpackChunkName: "sales-invoice-debit-notes-print" */ '@/views/sales/invoice-debit-notes/Print.vue')
  },
  /* #endregion [TRACKING] Nota débito de venta */

  /* #region [TRACKING] Remisiones de venta */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('SaleRemission', 1)
    },
    path: '/sales/remissions/:id/tracking',
    name: 'sales-remissions-tracking-index',
    component: () => import(/* webpackChunkName: "sales-remissions-tracking-index" */ '@/views/sales/remissions/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-print',
      requiresAuth: false,
      title: i18n.tc('SaleRemission', 2)
    },
    path: '/sales/remissions/:id/print',
    name: 'sales-remissions-print',
    component: () => import(/* webpackChunkName: "sales-remissions-print" */ '@/views/sales/remissions/Print.vue')
  },
  /* #endregion [TRACKING] Remisiones de venta */

  /* #region [TRACKING] Ordenes de venta */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('SaleOrder', 1)
    },
    path: '/sales/orders/:id/tracking',
    name: 'sales-orders-tracking-index',
    component: () => import(/* webpackChunkName: "sales-orders-tracking-index" */ '@/views/sales/orders/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-print',
      requiresAuth: false,
      title: i18n.tc('SaleOrder', 1)
    },
    path: '/sales/orders/:id/print',
    name: 'sales-orders-print',
    component: () => import(/* webpackChunkName: "sales-orders-print" */ '@/views/sales/orders/Print.vue')
  },
  /* #endregion [TRACKING] Ordenes de venta */

  /* #region [TRACKING] Cotizaciones de venta */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('Quote', 1)
    },
    path: '/sales/quotes/:id/tracking',
    name: 'sales-quotes-tracking-index',
    component: () => import(/* webpackChunkName: "sales-quote-tracking-index" */ '@/views/sales/quotes/tracking/Index.vue')
  },
  {
    meta: {
      layout: 'layout-print',
      requiresAuth: false,
      title: i18n.tc('Quote', 1)
    },
    path: '/sales/quotes/:id/print',
    name: 'sales-quotes-print',
    component: () => import(/* webpackChunkName: "sales-quotes-print" */ '@/views/sales/quotes/Print.vue')
  },
  /* #endregion [TRACKING] Cotizaciones de venta */

  /* #region [TRACKING] Facturas de compra */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('PurchaseInvoice', 1)
    },
    path: '/purchases/bills/:id/tracking',
    name: 'purchases-bills-tracking-index',
    component: () => import(/* webpackChunkName: "purchases-bills-tracking-index" */ '@/views/purchases/bills/tracking/Index.vue')
  },
  /* #endregion [TRACKING] Facturas de compras */

  /* #region [TRACKING] Notas de ajuste (Débito) */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('AdjustmentDebitNote')
    },
    path: '/purchases/bill-debit-notes/:id/tracking',
    name: 'purchases-bill-debit-notes-tracking-index',
    component: () => import(/* webpackChunkName: "purchases-bill-debit-notes-tracking-index" */ '@/views/purchases/bill-debit-notes/tracking/Index.vue')
  },
  /* #endregion [TRACKING] Notas de ajustes (Débito) */

  /* #region [TRACKING] Remisiones de compra */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('PurchaseRemission', 1)
    },
    path: '/purchases/remissions/:id/tracking',
    name: 'purchases-remissions-tracking-index',
    component: () => import(/* webpackChunkName: "purchases-remissions-tracking-index" */ '@/views/purchases/remissions/tracking/Index.vue')
  },
  /* #endregion [TRACKING] Remisiones de compras */

  /* #region [TRACKING] Ordenes de compra */
  {
    meta: {
      layout: 'layout-tracking',
      requiresAuth: false,
      title: i18n.tc('PurchaseOrder', 1)
    },
    path: '/purchases/orders/:id/tracking',
    name: 'purchases-orders-tracking-index',
    component: () => import(/* webpackChunkName: "purchases-orders-tracking-index" */ '@/views/purchases/orders/tracking/Index.vue')
  }
  /* #endregion [TRACKING] Ordenes de compras */
]
