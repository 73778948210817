import { keyBy } from 'lodash'

export const currencies = Object.freeze([
  { id: 'COP', code: 'COP', name: 'Peso colombiano', locale: 'es-CO' },

  { id: 'AFN', code: 'AFN', name: 'Afgani', locale: 'af-ZA' },
  { id: 'ARS', code: 'ARS', name: 'Peso argentino', locale: 'es-AR' },
  { id: 'AUD', code: 'AUD', name: 'Dólar australiano', locale: 'en-AU' },
  { id: 'BRL', code: 'BRL', name: 'Real brasileño', locale: 'pt-BR' },
  { id: 'CAD', code: 'CAD', name: 'Dólar canadiense', locale: 'en-CA' },
  { id: 'CNY', code: 'CNY', name: 'Yuan chino', locale: 'zh-CN' },
  { id: 'CLP', code: 'CLP', name: 'Peso chileno', locale: 'es-CL' },
  { id: 'EUR', code: 'EUR', name: 'Euro', locale: 'en-FR' },
  { id: 'GBP', code: 'GBP', name: 'Libra esterlina', locale: 'en-GB' },
  { id: 'MXN', code: 'MXN', name: 'Peso mexicano', locale: 'es-MX' },
  { id: 'PEN', code: 'PEN', name: 'Sol', locale: 'es-PE' },
  { id: 'USD', code: 'USD', name: 'Dólar estadounidense', locale: 'en-US' }
])

export const currencyLocale = Object.freeze(keyBy(currencies, 'code'))
