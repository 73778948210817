import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('Traceability')
    },
    path: '/logs',
    name: 'logs',
    component: () => import(/* webpackChunkName: "logs-index" */ '@/views/logs/Index.vue')
  }
]
