import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('DispatchOrder', 2)
    },
    path: '/order-dispatchs',
    name: 'order-dispatchs',
    component: () => import(/* webpackChunkName: "order-dispatchs" */ '@/views/order-dispatchs/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateDispatchOrder')
    },
    path: '/order-dispatchs/create',
    name: 'order-dispatchs-create',
    component: () => import(/* webpackChunkName: "order-dispatchs-create" */ '@/views/order-dispatchs/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateDispatchOrder')
    },
    path: '/order-dispatchs/create/:ids',
    name: 'order-dispatchs-create-by-ids',
    component: () => import(/* webpackChunkName: "order-dispatchs-create-by-ids" */ '@/views/order-dispatchs/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditDispatchOrder')
    },
    path: '/order-dispatchs/edit/:id',
    name: 'order-dispatchs-edit',
    component: () => import(/* webpackChunkName: "order-dispatchs-edit" */ '@/views/order-dispatchs/Edit.vue')
  }
]
