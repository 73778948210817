import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('FixedAsset', 2)
    },
    path: '/fixed-assets',
    name: 'fixed-assets',
    component: () => import(/* webpackChunkName: "fixed-assets-index" */ '@/views/fixed-assets/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('FixedAssetDetailedView', 2)
    },
    path: '/fixed-assets/:id/view',
    name: 'fixed-asset-detailed-view',
    component: () => import(/* webpackChunkName: "fixed-asset-detailed-view" */ '@/views/fixed-assets/DetailedView.vue')
  }
]
