<template>
  <b-breadcrumb
    separator="has-arrow-separator"
    class="is-size-6 mx-3 ml-2 is-hidden-mobile"
  >
    <b-breadcrumb-item
      tag="router-link"
      to="/"
    >
      {{ i18n.t('Home') }}
    </b-breadcrumb-item>

    <b-breadcrumb-item
      v-if="lastView"
      tag="router-link"
      :to="{name:lastRouteName, params: params, query: queryParams}"
    >
      {{ i18n.tc(lastView,2) }}
    </b-breadcrumb-item>

    <b-breadcrumb-item
      v-if="previousView"
      tag="router-link"
      :to="{name:previousRouteName, params: params, query: queryParams}"
    >
      {{ i18n.tc(previousView,2) }}
    </b-breadcrumb-item>
    <b-breadcrumb-item
      active
    >
      {{ i18n.tc(currentView,2) }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
import { i18n } from '@/plugins/i18n'
export default {
  name: 'TitleBar',
  props: {
    currentView: {
      type: String,
      default: () => ''
    },
    lastView: {
      type: String,
      default: () => ''
    },
    lastRouteName: {
      type: String,
      default: () => ''
    },
    previousView: {
      type: String,
      default: () => ''
    },
    previousRouteName: {
      type: String,
      default: () => ''
    },
    queryParams: {
      type: Object,
      default: () => null
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    return {
      i18n
    }
  }
}
</script>
