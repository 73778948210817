import Vue from 'vue'
import Vuex from 'vuex'
import modSubscription from './modules/subscriptionModule'
import modUser from './modules/userModule'
import modNotification from './modules/notificationModule'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    layout: 'layout-blank',
    routeFrom: '',
    routeTo: '',
    /* NavBar */
    isNavBarVisible: true,
    navBarColor: null,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,
    asideActiveForcedKey: null,
    isAsideRightVisible: false,
    isAsideRightActive: false,

    /* Updates */
    hasUpdates: true,

    /* Overlay */
    isOverlayVisible: false,

    /* Layout */
    isLayoutFullPage: false,
    isLayoutBoxed: false,
    isLayoutAsideHidden: false,
    isLayoutMobile: false,

    /* Dark mode (available with AppLightDark.vue only) */
    isDarkModeActive: false,

    /* Iframe */
    isIframePreviewMode: false,

    /* ConfigBox */
    isConfigBoxVisible: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    SET_USER (state, payload) {
      state.user = payload
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isConfigBoxVisible = !payload
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload
      state.isOverlayVisible = false
      state.isLayoutFullPage = payload
    },
    /* Aside Desktop Visibility */
    asideVisibilityToggle (state, payload) {
      state.isAsideVisible = payload
    },

    /* Aside Desktop State */
    asideStateToggle (state, payload = null) {
      const isExpand = payload !== null ? payload : !state.isAsideExpanded

      document.documentElement.classList[isExpand ? 'add' : 'remove']('has-aside-expanded')

      state.isAsideExpanded = isExpand
    },

    /* Aside Mobile State */
    asideMobileStateToggle (state, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.documentElement.classList[isShow ? 'add' : 'remove']('has-aside-mobile-expanded')

      state.isAsideVisible = !state.isLayoutFullPage
      state.isAsideMobileExpanded = isShow
    },

    /* Aside Forced Active Key (when secondary submenu is open) */
    asideActiveForcedKeyToggle (state, payload) {
      state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null
    },

    /* Aside Right */
    asideRightToggle (state, payload) {
      state.isAsideRightVisible = payload
      state.isAsideRightActive = payload
      state.hasUpdates = false
    },

    /* Overlay */
    overlayToggle (state, payload = null) {
      const setIsVisible = payload !== null ? payload : !state.isOverlayVisible

      if (!setIsVisible && state.isLayoutAsideHidden && (state.isAsideVisible || state.isAsideRightVisible)) {
        return
      }

      state.isOverlayVisible = setIsVisible

      document.documentElement.classList[setIsVisible ? 'add' : 'remove']('is-clipped')
    },

    /* Layouts */
    layoutBoxedToggle (state, payload = null) {
      const setIsBoxed = payload !== null ? payload : !state.isLayoutBoxed

      state.isLayoutAsideHidden = setIsBoxed
      state.isLayoutBoxed = setIsBoxed
      state.isAsideExpanded = setIsBoxed
      state.isAsideVisible = !setIsBoxed
      state.isAsideRightVisible = false
      state.isAsideRightActive = false

      document.documentElement.classList[setIsBoxed ? 'remove' : 'add']('has-aside-left', 'has-navbar-fixed-top')
      document.documentElement.classList[setIsBoxed ? 'add' : 'remove']('has-boxed-layout', 'has-aside-hidden-layout', 'has-aside-expanded')
    },

    layoutWideToggle (state, payload = null) {
      const setIsWide = payload !== null ? payload : !state.isLayoutBoxed

      state.isLayoutAsideHidden = setIsWide
      state.isAsideExpanded = setIsWide
      state.isAsideVisible = !setIsWide
      state.isAsideRightVisible = !setIsWide

      document.documentElement.classList[setIsWide ? 'remove' : 'add']('has-aside-left')
      document.documentElement.classList[setIsWide ? 'add' : 'remove']('has-aside-hidden-layout', 'has-aside-expanded')
    },

    layoutMobileToggle (state, payload) {
      state.isLayoutMobile = payload
    },

    /* Dark Mode */
    darkModeToggle (state, payload = null) {
      const setIsDark = payload !== null ? payload : !state.isDarkModeActive

      state.isDarkModeActive = setIsDark

      document.documentElement.classList[setIsDark ? 'add' : 'remove']('is-dark-mode-active')
    },

    /* Misc */
    setNavBarColor (state, payload) {
      state.navBarColor = payload
    },

    iframePreviewMode (state, payload) {
      state.isIframePreviewMode = payload
    },
    SET_FROM (state, from) {
      state.routeFrom = from
    },
    SET_TO (state, to) {
      state.routeTo = to
    },
    SET_LAYOUT (state, payload) {
      state.layout = payload
    }
  },
  actions: {
    asideCloseAll ({ commit }) {
      commit('asideVisibilityToggle', false)
      commit('asideRightToggle', false)
      commit('overlayToggle', false)
    },
    asideVisibilityToggle ({ commit, state }, payload = null) {
      const setIsVisible = payload !== null ? payload : !state.isAsideVisible

      commit('asideVisibilityToggle', setIsVisible)
      commit('overlayToggle', setIsVisible)
    },
    asideRightToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideRightVisible

      commit('asideRightToggle', isShow)

      if (state.isLayoutAsideHidden) {
        commit('overlayToggle', isShow)
      }

      if (!state.isLayoutAsideHidden) {
        document.documentElement.classList[isShow ? 'add' : 'remove']('has-aside-right')
      }
    },
    layoutMobileToggle ({ commit, state }) {
      const isMobile = window.innerWidth < 1024
      commit('layoutMobileToggle', isMobile)

      document.documentElement.classList[isMobile && state.isIframePreviewMode ? 'add' : 'remove']('iframe-preview-mode')
    },
    toggleFullPage ({ commit }, payload) {
      commit('layoutBoxedToggle', false)
      commit('fullPage', payload)

      document.documentElement.classList.remove('is-clipped')

      if (payload) {
        document.documentElement.classList.remove('has-aside-left', 'has-navbar-fixed-top')
      }
    },
    toggleFullPageBlank ({ commit }, payload) {
      commit('layoutBoxedToggle', false)
      commit('fullPageBlank', payload)

      document.documentElement.classList.remove('is-clipped')

      if (payload) {
        document.documentElement.classList.remove('has-aside-left', 'has-navbar-fixed-top')
      }
    },
    setFrom ({ commit }, from) {
      commit('SET_FROM', from)
    },
    setTo ({ commit }, to) {
      commit('SET_TO', to)
    }
  },
  getters: {
    viewFromPath: state => {
      if (state.routeFrom.name && state.routeFrom.name.indexOf('index') !== -1) {
        return state.routeFrom.path
      }
      return ''
    },
    viewFromLabel: state => {
      if (state.routeFrom.meta && state.routeFrom.meta.title) {
        return state.routeFrom.meta.title
      }
      return ''
    },
    viewToLabel: state => {
      if (state.routeTo.meta.title) {
        return state.routeTo.meta.title
      }
      return ''
    },
    layout (state) {
      return state.layout
    }
  },
  modules: {
    modSubscription,
    modUser,
    modNotification
  }
})

export default store

export function useStore () {
  return store
}
