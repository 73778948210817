import { i18n } from '@/plugins/i18n'

export default [

  /* ---------------- Consultar empleados - (ConsultEmployees) ---------------- */
  {
    meta: {
      title: i18n.tc('Employee', 2)
    },
    path: '/payroll-lite/employees',
    name: 'payroll-lite-employees-index',
    component: () => import(/* webpackChunkName: "payroll-lite-employees-index" */ '@/views/payroll-lite/employees/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateEmployee', 1)
    },
    path: '/payroll-lite/employees/create',
    name: 'payroll-lite-employees-create',
    component: () => import(/* webpackChunkName: "payroll-lite-employees-create" */ '@/views/payroll-lite/employees/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditEmployee', 1)
    },
    path: '/payroll-lite/employees/:id/edit',
    name: 'payroll-lite-employees-edit',
    component: () => import(/* webpackChunkName: "payroll-lite-employees-edit" */ '@/views/payroll-lite/employees/Edit.vue')
  },

  /* --------------------------------- Consultar Nóminas - (ConsultPayrolls) -------------------------------- */
  {
    meta: {
      title: i18n.tc('ConsultPayroll', 2)
    },
    path: '/payroll-lite/payrolls',
    name: 'payroll-lite-payrolls-index',
    component: () => import(/* webpackChunkName: "payroll-lite-payrolls-index" */ '@/views/payroll-lite/payrolls/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('EditPayroll')
    },
    path: '/payroll-lite/payrolls/:year/:month/:id/edit',
    name: 'payroll-lite-payrolls-edit',
    component: () => import(/* webpackChunkName: "payroll-lite-payrolls-edit" */ '@/views/payroll-lite/payrolls/Edit.vue')
  },

  /* --------------------------------- Consultar Ajustes - (ConsultAdjustments) -------------------------------- */
  {
    meta: {
      title: i18n.tc('ConsultAdjustment', 2)
    },
    path: '/payroll-lite/adjustments',
    name: 'payroll-lite-adjustments-index',
    component: () => import(/* webpackChunkName: "payroll-lite-adjustments-index" */ '@/views/payroll-lite/adjustments/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustmentNoteReplace')
    },
    path: '/payroll-lite/adjustments/:year/:month/:id/:adjustmentFor/replace',
    name: 'payroll-lite-adjustments-replace',
    component: () => import(/* webpackChunkName: "payroll-lite-adjustments-replace" */ '@/views/payroll-lite/adjustments/Replace.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustmentNoteEdit')
    },
    path: '/payroll-lite/adjustments/:year/:month/:id/edit',
    name: 'payroll-lite-adjustments-edit',
    component: () => import(/* webpackChunkName: "payroll-lite-adjustments-edit" */ '@/views/payroll-lite/adjustments/Edit.vue')
  }
]
