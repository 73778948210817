/* eslint-disable no-console */
import { i18n } from '@/plugins/i18n'
import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy'
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  let isPreviouslyInstalled = false
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      isPreviouslyInstalled =
      registrations.filter((item) =>
        item && item.active && item.active.scriptURL.includes('service-worker.js')
      ).length > 0

      register(`${process.env.BASE_URL}service-worker.js`, {
        ready () {
          console.log(
            '%c⧭',
            'color: #00bf00',
            'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
          )
        },
        registered () {
          console.log(
            '%c%s',
            'color: #aa00ff',
            'Service worker has been registered.'
          )
        },
        cached () {
          console.log(
            '%c⧭',
            'color: #0088cc',
            'Content has been cached for offline use.'
          )
        },
        updatefound () {
          console.log('%c%s', 'color: #733d00', 'New content is downloading.', isPreviouslyInstalled)
          if (isPreviouslyInstalled) {
            Toast.open({
              duration: 5000,
              message: i18n.tc('AppReleaseNewContent'),
              pauseOnHover: true,
              position: 'is-bottom'
            })
          }
        },
        reload () {
          console.log('%c⧭', 'color: #f200e2', 'reloading..')
          navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (const registration of registrations) {
              registration.unregister()
            }
          })
          Toast.open({
            duration: 5000,
            message: i18n.tc('AppReloading'),
            pauseOnHover: true,
            position: 'is-bottom'
          })
          setTimeout(() => {
            window.location.reload(true)
          }, 2000)
        },
        updated () {
          console.log(
            '%c%s',
            'color: #00e600',
            'New content is available: Please refresh.'
          )
          const _this = this

          console.log('%c⧭', 'color: #ff0000', 'isPreviouslyInstalled', isPreviouslyInstalled)
          if (isPreviouslyInstalled) {
            const timeoutId = setTimeout(() => {
              _this.reload()
            }, 300000)

            Dialog.confirm({
              cancelText: 'Recargar en 5 minutos',
              confirmText: 'Recargar Ahora',
              message: i18n.tc('AppNewReleaseMessage'),
              onConfirm: () => {
                clearTimeout(timeoutId)
                _this.reload()
              },
              title: i18n.tc('AppNewRelease'),
              type: 'is-success'
            })
          }
        },
        offline () {
          console.log(
            '%c%s',
            'color: #00bf00',
            'No internet connection found. App is running in offline mode.'
          )
          Toast.open({
            message: 'No hay conexión a internet',
            type: 'is-danger'
          })
        },
        error (error) {
          console.log(
            '%c%s %s',
            'color: #0088cc',
            'Error during service worker registration:',
            error
          )
        }
      })
    })
  }
}
