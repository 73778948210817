export default [
  {
    meta: {
      title: ''
    },
    path: '/exports',
    name: 'exports',
    component: () => import(/* webpackChunkName: "exports-index" */ '@/views/exports/Index.vue')
  }
]
