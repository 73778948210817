import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('ConsultInventory', 2)
    },
    path: '/inventories',
    name: 'inventories-index',
    component: () => import(/* webpackChunkName: "inventories-index" */ '@/views/inventories/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryMovement', 2)
    },
    path: '/inventories/detail',
    name: 'inventories-detail',
    component: () => import(/* webpackChunkName: "inventories-detail-index" */ '@/views/inventories/detail/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustStock')
    },
    path: '/inventories/adjust-stock',
    name: 'inventories-adjust-stock',
    component: () => import(/* webpackChunkName: "inventories-adjust-stock" */ '@/views/inventories/AdjustStock.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustCost')
    },
    path: '/inventories/adjust-costs',
    name: 'inventories-adjust-costs',
    component: () => import(/* webpackChunkName: "inventories-adjust-costs" */ '@/views/inventories/AdjustCosts.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateInventory')
    },
    path: '/inventories/create/:type',
    name: 'inventories-create',
    component: () => import(/* webpackChunkName: "inventories-create" */ '@/views/inventories/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditInventory')
    },
    path: '/inventories/edit/:id',
    name: 'inventories-edit',
    component: () => import(/* webpackChunkName: "inventories-edit" */ '@/views/inventories/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryTransfer')
    },
    path: '/inventories/transfer',
    name: 'inventories-transfer',
    component: () => import(/* webpackChunkName: "inventories-transfer" */ '@/views/inventories/Transfer.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryTransfer')
    },
    path: '/inventories/transfer-batch',
    name: 'inventories-transfer-batch',
    component: () => import(/* webpackChunkName: "inventories-transfer-batch" */ '@/views/inventories/TransferBatch.vue')
  },
  {
    meta: {
      title: i18n.tc('InventoryLoss')
    },
    path: '/inventories/loss',
    name: 'inventories-loss',
    component: () => import(/* webpackChunkName: "inventories-loss" */ '@/views/inventories/Loss.vue')
  },
  {
    meta: {
      title: i18n.tc('TransferBatchEdit')
    },
    path: '/inventories/transfer-batch-edit/:id',
    name: 'inventories-transfer-batch-edit',
    component: () => import(/* webpackChunkName: "inventories-transfer-batch-edit" */ '@/views/inventories/TransferBatchEdit.vue')
  },
  {
    meta: {
      title: i18n.tc('EditInventoryTransfer')
    },
    path: '/inventories/transfer-edit/:id',
    name: 'inventories-transfer-edit',
    component: () => import(/* webpackChunkName: "inventories-transfer-edit" */ '@/views/inventories/TransferEdit.vue')
  },
  {
    meta: {
      title: 'Requisición de inventario'
    },
    path: '/inventories/requisitions',
    name: 'inventories-requisitions',
    component: () => import('@/views/inventories/requisitions/Index.vue')
  },
  {
    meta: {
      title: 'Crear requisición'
    },
    path: '/inventories/requisitions/create',
    name: 'inventories-requisitions-create',
    component: () => import('@/views/inventories/requisitions/Create.vue')
  },
  {
    meta: {
      title: 'Editar requisición'
    },
    path: '/inventories/requisitions/edit/:id',
    name: 'inventories-requisitions-edit',
    component: () => import('@/views/inventories/requisitions/Edit.vue')
  }
]
