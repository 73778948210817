export default [
  {
    meta: {
      title: 'Vista de Terceros'
    },
    path: '/people/:id/view',
    name: 'people-view',
    component: () => import(/* webpackChunkName: "people-view" */ '@/views/people/ViewPeople.vue')
  },
  {
    meta: {
      title: 'Terceros'
    },
    path: '/people',
    name: 'people-index',
    component: () => import(/* webpackChunkName: "people-index" */ '@/views/people/Index.vue')
  },
  {
    meta: {
      title: 'Detalles del tercero'
    },
    path: '/people/detail/:id',
    name: 'people-detail',
    component: () => import(/* webpackChunkName: "people-detail" */ '@/views/people/Detail.vue')
  }
]
